















































































































































































































.knowledge-base-jumbotron-content {
  background-color: #ea5455;
  background-size: cover;
}

.p-box {
  /* the other rules */
  transition: box-shadow 0.3s;
}
.p-box:hover {
  box-shadow: 20px 10px 40px 0 rgba(0, 0, 0, 0.5);
}
.card-save {
  padding: 20px;
}
